import React from 'react'
import '../Styles/Clients.css'

function Clients() {
  const bhu = require('../Assets/OurClients/images_govt/bhu.png')
  const bhumc = require('../Assets/OurClients/images_govt/bhumc.jpg')
  const brdmc = require('../Assets/OurClients/images_govt/brdmc.png')
  const firoz = require('../Assets/OurClients/images_govt/firoza.webp')
  const gsvm = require('../Assets/OurClients/images_govt/gsvm.jpg')
  const hmc = require('../Assets/OurClients/images_govt/hmc.jpg')
  const integral = require('../Assets/OurClients/images_govt/integral.jpg')
  const kgmc = require('../Assets/OurClients/images_govt/kgmc.jpg')
  const mayocli = require('../Assets/OurClients/images_govt/mayocli.jpg')
  const mlbmc = require('../Assets/OurClients/images_govt/mlbmc.jpg')
  const mnmc = require('../Assets/OurClients/images_govt/mnmc.jpg')
  const patiyala = require('../Assets/OurClients/images_govt/patiala.png')
  const rmcbd = require('../Assets/OurClients/images_govt/rmcbd.jpg')
  const rmlimc = require('../Assets/OurClients/images_govt/rmlimc.jpg')
  const sgmpims = require('../Assets/OurClients/images_govt/sgmpims.png')

  const govt_logos = [
    bhu, bhumc, brdmc, firoz, gsvm, hmc, integral, kgmc, mayocli,
    mlbmc,mnmc, patiyala, rmcbd, rmlimc, sgmpims, bhumc,
    
  ]
  //White a map function


  return (
    <div id='clients' className="client-section">
    <h2>Our Clients</h2>
      <div className="logo-container">
        {
          govt_logos.map((image)=>(
            <img src={image} alt="" />
          ))
        }
      </div>
    </div>
  )
}

export default Clients