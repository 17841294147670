import React from 'react'
import '../Styles/Footer.css'
function Footer() {
  return (
    <section className='footer-section'>
      <div className="footer-container">
        &copy; 2017 Mars Healthcare
      </div>
    </section>
  )
}

export default Footer