import './App.css';
import Appointment from './Components/Appointment';
import CategoryProducts from './Components/CategoryProducts';
import Footer from './Components/Footer';
import Navigation from './Components/Navigation';
import Homepage from './Pages/Homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Navigation />
      <div className="App">
      <Router>
        <Routes>
          <Route path='/' exact element={<Homepage/>} />
          <Route path='/products/:category' element={<CategoryProducts />} />
          <Route path='/appointment' element={<Appointment/>}/>
        </Routes>
      </Router>
      </div>
      <Footer/>
    </>
  );
}

export default App;
