import React from 'react'
import Hero from '../Components/Hero'
import Brands from '../Components/Brands'
import Mission from '../Components/Mission'
import About from '../Components/About'
import Clients from '../Components/Clients'
import Establishment from '../Components/Establishment'
import Featured from '../Components/Featured'

function Homepage() {
    return (
        <>
            <Hero />
            <Brands />
            <Mission/>
            <About/>
            <Clients/>
            <Establishment/>
            <Featured/>
        </>

    )
}

export default Homepage